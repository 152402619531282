import { makeStyles, Typography, TypographyProps } from "@material-ui/core";

type FontWeight = "normal" | "600" | "500" | "300";
interface CustomTypographyProps extends TypographyProps {
  fontWeight?: FontWeight;
}

const WEIGHT_NORMAL = "weightNormal";
const WEIGHT_600 = "weight600";
const WEIGHT_500 = "weight500";
const WEIGHT_300 = "weight300";

const useStyles = makeStyles((_) => {
  return {
    [WEIGHT_NORMAL]: {
      fontWeight: "normal",
    },
    [WEIGHT_600]: {
      fontWeight: 600,
    },
    [WEIGHT_500]: {
      fontWeight: 500,
    },
    [WEIGHT_300]: {
      fontWeight: 300,
    },
  };
});

const getFontWeight = (weight: FontWeight) => {
  switch (weight) {
    case "normal":
      return WEIGHT_NORMAL;
    case "600":
      return WEIGHT_600;
    case "500":
      return WEIGHT_500;
    case "300":
      return WEIGHT_300;
  }
};

export const CustomTypography: React.FC<CustomTypographyProps> = ({
  fontWeight,
  className,
  ...rest
}) => {
  const styles = useStyles();
  const fontWeightString = fontWeight ? styles[getFontWeight(fontWeight)] : "";
  return (
    <Typography
      {...rest}
      className={`${className} ${fontWeightString}`}
    ></Typography>
  );
};
