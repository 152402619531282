import { CustomError, CustomServerResponse } from "interfaces";
import { BAD_RESPONSE, HTTP_CODE_4XX, HTTP_CODE_5XX, BAD_REQUEST, BAD_SERVER, ERROR_MESSAGE } from "./data";

export const useErrorHandler = () => { 
  const getErrorMessage = (message: string, status: number) => ({
    message,
    status,
  });
  
  const getError = ({
    ok,
    status,
    body,
  }: CustomServerResponse<any>): CustomError | undefined => {
    if (!ok) {
      return getErrorMessage(BAD_RESPONSE, status);
    } else if (status >= HTTP_CODE_4XX && status < HTTP_CODE_5XX) {
      return getErrorMessage(BAD_REQUEST, status);
    } else if (status >= HTTP_CODE_5XX) {
      return getErrorMessage(BAD_SERVER, status);
    } else {
      return getCustomError(body);
    }
  };
  
  const getCustomError = (body: any): CustomError | undefined => {
    if (body["name"] === ERROR_MESSAGE) {
      return getErrorMessage(body["message"] || ERROR_MESSAGE, HTTP_CODE_5XX);
    }
    return undefined;
  };
  
  const handle = (
    response: CustomServerResponse<any>
  ): CustomError | undefined => {
    return getError(response) || undefined;
  };

  return {
    handle,
  };
}