import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { IconButton } from "@material-ui/core";
import { MenuRounded } from "@material-ui/icons";

const useStyles = makeStyles({
  list: {
    overflow: "hidden",
  },
  fullList: {
    width: "auto",
  },
  menuButton: {
    color: "#f3f3f3"
  },
  menuButtonContainer: {
    backgroundColor: "#3a3a3a",
    width: "100%"
  }
});

type RKEventDiv = React.KeyboardEvent<HTMLDivElement>;

interface LayoutDrawerProps {
  mobile?: boolean;
}
export const LayoutDrawer: React.FC<LayoutDrawerProps> = ({
  children,
  mobile = false,
}) => {
  const styles = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
  });
  const [isMobile] = useState<boolean>(mobile);

  const toggleDrawer = (open: boolean) => (
    event:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (
      event.type === "keydown" &&
      ((event as RKEventDiv).key === "Tab" ||
        (event as RKEventDiv).key === "Shift")
    ) {
      return;
    }
    if (isMobile) {
      setState({ ...state, top: open });
    } else {
      setState({ ...state, left: open });
    }
  };

  return (
    <div key={"left-drawer"} className={styles.menuButtonContainer}>
      <IconButton href="#" onClick={toggleDrawer(true)} className={styles.menuButton}>
        <MenuRounded/>
      </IconButton>
      <Drawer
        anchor={mobile ? "top" : "left"}
        open={state[mobile ? "top" : "left"]}
        onClose={toggleDrawer(false)}
      >
        <div
          className={clsx(styles.list)}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          {children}
        </div>
      </Drawer>
    </div>
  );
};
