import { makeStyles } from "@material-ui/core";
import { CardInfo, CardList } from "components/CardList";
import { CustomTypography } from "components/CustomTypography";
import { useGetRepoInformation } from "hooks";
import { AcceptedPlatform } from "interfaces";

interface RepoSectionProps {
  title: string;
  platform: AcceptedPlatform;
}

const useStyles = makeStyles((_) => ({
  repoTitleContainer: {
    backgroundColor: "#f3f3f3",
    height: "140px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "50px",
    marginBottom: "25px"
  }
}));
export const RepoSection: React.FC<RepoSectionProps> = ({
  platform,
  title,
}) => {
  const styles = useStyles();
  const repoData = useGetRepoInformation(platform);
  const cardInfo: CardInfo[] = repoData
    ? repoData.map((data) => ({ ...data, lastUpdated: data.lastActivity }))
    : [];
  return repoData ? (
    <>
      <CustomTypography variant="h3" className={styles.repoTitleContainer} fontWeight="600">
        {title}
      </CustomTypography>
      <CardList cardsInfo={cardInfo} />
    </>
  ) : null;
};
