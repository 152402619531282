import { SocialNetwork } from "./social-info.interface";

const GITLAB_BASE = "https://gitlab.com/";
const GITHUB_BASE = "https://github.com/";
const LINKEDIN_BASE = "https://linkedin.com/in/";

const getBase = (socialNetwork: SocialNetwork) => {
  switch (socialNetwork) {
    case "github":
      return GITHUB_BASE;
    case "gitlab":
      return GITLAB_BASE;
    case "linkedin":
      return LINKEDIN_BASE;
  }
};

export const mapSocialNetworkToUrl = (
  socialNetwork: SocialNetwork,
  username: string
) => {
  return `${getBase(socialNetwork)}${username}`;
};
