import { Repo, RepoVM } from "interfaces";

export const useRepoMapper = () => {
  const mapArray = (repoData: Repo[]): RepoVM[] =>
    repoData.map((data) => map(data));

  const map = (repo: Repo): RepoVM => ({
    ...repo,
    lastActivity: repo.last_activity_at,
    url: repo.web_url,
  });

  return {
    mapArray,
    map,
  };
}
