import { makeStyles } from "@material-ui/core";
import { CustomTypography } from "components/CustomTypography";
import { SocialStrip } from "components/SocialStrip/SocialStrip";
import "./SideBar.css";

const useStyles = makeStyles((_) => {
  return {
    sideBar: {
      backgroundColor: "#3a3a3a",
    },
    nameTitle: {
      marginBottom: "5px",
    },
  };
});
export const Sidebar = () => {
  const styles = useStyles();
  return (
    <div className={`personal ${styles.sideBar}`}>
      <figure className="self-photo">
        <img src="zyoruk-logo.svg" alt="Zyoruk sitting on a staircase" />
      </figure>
      <CustomTypography variant="h5" fontWeight="500" className={styles.nameTitle}>
        Luis Ángel Barrantes
      </CustomTypography>
      <CustomTypography fontWeight="normal" variant="subtitle1">
        Software Developer
      </CustomTypography>
      <SocialStrip
        socialInfo={[
          { username: "zyoruk", socialNetwork: "github" },
          { username: "zyoruk", socialNetwork: "gitlab" },
          { username: "zyoruk", socialNetwork: "linkedin" },
        ]}
      />
    </div>
  );
};
