import { AcceptedPlatform, CustomResponse, Repo, RepoVM } from "interfaces";
import { useCallback } from "react";
import { useRepoMapper, useResponseHandler } from ".";
import { BASE_PATH, HTTP_CODE_2XX } from "./data";

export const useRepoService = () => { 
  const { mapArray } = useRepoMapper();
  const { handle } = useResponseHandler();

  const getData = useCallback(async (repo: AcceptedPlatform): Promise<RepoVM[]> => {
    const response = await fetch(`${BASE_PATH}/projects?platform=${repo}`);
    const handledResponse = await handle<Repo[]>(response);
    if (handledResponse.status !== HTTP_CODE_2XX) {
      return [];
    }
    return mapArray((handledResponse as CustomResponse<Repo[]>).data);
  }, [handle, mapArray]);

  return { 
    getData,
  };
}