import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";

export interface CardInfo {
  url: string;
  name: string;
  description: string;
  lastUpdated: string;
}

// #A4D92C
// #66d9ed
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#f3f3f3",
    borderRadius: "20px"
  },
  cardTitle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "60%",
  },
  cardDesc: {
    height: "100px",
  },
  cardActions: {
    display: "flex",
    justifyContent: "end",
  },
  codeButton: {
    "&:hover": {
      color: "#66d9ed",
      backgroundColor: "#FFF",
    },
    color: "#fff",
    backgroundColor: "#A4D92C",
    borderRadius: "50%",
    position: "relative",
    right: 0,
    fontWeight: "bold",
  },
  removeUnderline: {
    textDecoration: "none",
  },
}));

export const CardItem: React.FC<CardInfo> = ({
  url,
  name,
  description,
  lastUpdated,
}) => {
  const styles = useStyles();
  return (
    <a href={url} target="_blank" rel="noreferrer">
      <Card raised className={styles.card}>
        <CardHeader
          title={
            <Tooltip title={name}>
              <Typography
                className={`${styles.removeUnderline} ${styles.cardTitle}`}
                variant="h5"
                noWrap
              >
                {name}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Typography className={styles.removeUnderline}>
              Last updated: {lastUpdated}
            </Typography>
          }
        />
        <CardContent className={styles.cardDesc}>
          <Typography>{description}</Typography>
        </CardContent>
        <CardActions className={styles.cardActions}>
          <IconButton className={`${styles.codeButton} cardButton`}>
            <CodeIcon></CodeIcon>
          </IconButton>
        </CardActions>
      </Card>
    </a>
  );
};
