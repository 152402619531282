import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";

interface SocialIconProps {
  url: string;
  iconId: string;
  color?: string;
}

const useStyles = makeStyles((_) => {
  return {
    socialIconClass: {
      "&:hover": {
        color: "#66d9ed",
      },
    },
  };
});

export const SocialIcon: React.FC<SocialIconProps> = ({
  iconId,
  url,
  color = "#f3f3f3",
}) => {
  const styles = useStyles();
  const socialIconClass = `social fab fa-lg fa-${iconId}`;
  return (
    <a href={url} target="_blank" rel="oopener noreferrer">
      <Tooltip title={iconId}>
        <i className={clsx(socialIconClass, styles.socialIconClass)} color={color}></i>
      </Tooltip>
    </a>
  );
};
