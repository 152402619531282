import { RepoSection } from "components/RepoSection";
import "./App.css";
import { Layout } from "components/Layout";
import { BioHelmet } from "components/BioHelmet";
import { createTheme, ThemeProvider } from "@material-ui/core";

export const App = (): JSX.Element => {
  const theme = createTheme({
    typography: {
      fontFamily: ["'Poppins'", "sans-serif"].join(","),
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <BioHelmet />
        <Layout>
          <RepoSection platform="gitlab" title="Gitlab repos" />
          <RepoSection platform="github" title="Github repos" />
        </Layout>
      </ThemeProvider>
    </>
  );
};
