import { Helmet } from "react-helmet";

export const BioHelmet = () => {
  const title = "Zyoruk Repos";
  const description = "Web app to list public repos from Gitlab and Github.";
  const image = "zyoruk-logo.svg";
  const url = "https://bio.devpand.com";
  const keyWords =
    "git, CSS, HTML, CSS, Material Design, React, Netlify, Github, Gitlab";

  return (
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#3a3a3a" />
      <meta charSet="UTF-8" />
      <meta name="keywords" content={keyWords} />
      <meta name="author" content="Zyoruk" />
      <meta name="robots" content="follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content={description} />
      <meta property="og:title" content="Zyoruk Repos" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Zyoruk Repo" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content="Zyoruk's logo"></meta>
      <link rel="icon" href="zyoruk-logo.svg" />
      <link rel="apple-touch-icon" href="zyoruk-logo.svg" />
      <link rel="manifest" href="manifest.json" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Material+Icons&family=Poppins:ital,wght@0,400;0,500;0,600;1,300&display=swap"
        rel="stylesheet"
      />
      <title>Zyoruk Bio</title>
    </Helmet>
  );
};
