/* eslint-disable jsx-a11y/anchor-is-valid */
import { Grid, Hidden, makeStyles, Theme } from "@material-ui/core";
import { Sidebar } from "../SideBar";
import { LayoutDrawer } from "components/LayoutDrawer";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden"
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  sideBar: {
    backgroundColor: theme.palette.primary.main,
  },
  rightPanel: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "100vh",
  },
}));

export const Layout: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Hidden lgUp>
        <LayoutDrawer mobile>
          <Sidebar />
        </LayoutDrawer>
      </Hidden>
      <Hidden mdDown>
        <Grid item xs={3} md={3}>
          <Sidebar />
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <main className={classes.rightPanel}>{children}</main>
      </Grid>
    </Grid>
  );
};
