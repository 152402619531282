import { SocialIcon } from "components/SocialIcon";
import { SocialInfo } from "./social-info.interface";
import { mapSocialNetworkToUrl } from "./social-strip.utils";

interface SocialStripProps {
  socialInfo: SocialInfo[];
}

export const SocialStrip: React.FC<SocialStripProps> = ({ socialInfo }) => {
  return (
    <>
      {socialInfo.map(({ socialNetwork, username }) => (
        <SocialIcon
          key={socialNetwork}
          iconId={socialNetwork}
          url={mapSocialNetworkToUrl(socialNetwork, username)}
        />
      ))}
    </>
  );
};
