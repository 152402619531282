import { CustomError, CustomResponse, CustomServerResponse } from "interfaces";
import { useErrorHandler } from ".";
import { HTTP_CODE_2XX } from "./data";

export const useResponseHandler = () =>  {
  const { handle: handleError } = useErrorHandler();

  const handle = async <T extends unknown>(
    response: Response
  ): Promise<CustomResponse<T> | CustomError> => {
    const customResponse: CustomServerResponse<T> = {
      ok: response.ok,
      status: response.status,
      body: await response.json(),
    };
    const error = handleError(customResponse);
    return (
      error || {
        status: HTTP_CODE_2XX,
        data: customResponse.body,
      }
    );
  };

  return {
    handle,
  };
}
