import { AcceptedPlatform, RepoVM } from "interfaces";
import { useEffect, useState } from "react";
import { useRepoService } from ".";

export const useGetRepoInformation = (platform: AcceptedPlatform) => {
  const [repoData, setReposData] = useState<RepoVM[]>();
  const { getData } = useRepoService();

  useEffect(() => {
    const getRepoInformation = async () => {
      const data = await getData(platform);
      setReposData(data);
    };

    getRepoInformation();
  }, [platform, getData]);

  return repoData;
};
